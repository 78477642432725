<template>
  <div
    id="video-app"
    class="bg-video-app"
  >
    <div class="d-flex align-center justify-center logo-video-app">
      <v-img
        :src="appLogo"
        max-height="40px"
        max-width="40px"
        alt="logo"
        contain
        class="me-3 "
      ></v-img>
      <span
        class="font-weight-bold text-lg"
        style="color: white !important; font-family: 'Orbitron';"
      >
        {{ appName }}
      </span>
    </div>

    <div class="header-video-app d-flex justify-center">
      <div class="d-flex align-center">
        <v-avatar
          v-if="videoData.share_user && videoData.share_user !== 'undefined'"
          :color="videoData.share_user_avatar ? '' : 'secondary'"
          :class="videoData.share_user_avatar ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
          size="32"
        >
          <v-img
            :src="videoData.share_user_avatar ? videoData.share_user_avatar : require(`@/assets/images/avatars/user-hp.png`)"
          ></v-img>
        </v-avatar>
        <v-btn
          class="text-sm ma-0 pa-0"
          style="text-transform: inherit;"
          text
          depressed
          @click="downloadApp"
        >
          <span class="white--text text-sm">
            <!-- {{ (videoData.share_user && videoData.share_user !== 'undefined' ? () : ({{ t('videos.dont_have_fanaty') }}) }} -->
            <span v-if="videoData.share_user && videoData.share_user !== 'undefined'">{{ videoData.share_user }} {{ t('videos.using') }}</span>
            <span v-else>{{ t('videos.dont_have_fanaty') }}</span>
            <strong>{{ t('videos.get_app') }}</strong>
          </span>
        </v-btn>
      </div>
    </div>

    <div
      class="auth-wrapper auth-v1"
    >
      <div
        class="auth-inner"
        style="width:50rem;"
      >
        <v-row class="d-flex justify-center">
          <v-col
            cols="12"
            class="align-self-start pa-0"
          >
            <v-card
              style="margin-bottom: 0px;"
              color="#000000"
              class="rounded-lg mx-auto"
              elevation="15"
              max-width="600"
            >
              <v-card-text class="pa-3">
                <div>
                  <div
                    class="ms-0 width-full"
                  >
                    <v-row class="pa-1">
                      <v-col
                        cols="12"
                        class="mb-1 pa-1 d-flex justify-space-between align-center"
                      >
                        <div class="d-flex align-center">
                          <v-avatar
                            :color="videoData.facility_logo ? '' : 'secondary'"
                            :class="videoData.facility_logo ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
                            size="40"
                          >
                            <v-img
                              v-if="videoData.facility_logo"
                              :src="videoData.facility_logo"
                            ></v-img>
                            <span
                              v-else
                              class="
                              font-weight-medium"
                            >
                              {{ avatarText(videoData.facility_name) }}
                            </span>
                          </v-avatar>
                          <v-col>
                            <v-row>
                              <span class="text-body-1 font-weight-bold white--text">{{ videoData.field_name }}</span>
                            </v-row>
                            <v-row>
                              <span class="text-sm">{{ videoData.facility_name }}</span>
                            </v-row>
                          </v-col>
                        </div>
                        <div>
                          <v-col>
                            <v-row justify="end">
                              <span class="text-sm">{{ videoData.video_time }}</span>
                            </v-row>
                            <v-row justify="end">
                              <span class="text-sm">{{ videoData.video_date }}</span>
                            </v-row>
                          </v-col>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-card-text>

              <v-carousel
                height="auto"
                hide-delimiters
                show-arrows-on-hover
                cycle
                class="justify-center"
                :show-arrows="false"
              >
                <v-carousel-item
                  v-if="videoData.video_thumbnail"
                  eager
                  @click="linkVideo(videoData.video_status)"
                >
                  <v-img
                    class="img-h mx-auto"
                    max-width="600"
                    max-height="300"
                    eager
                    :src="videoData.video_thumbnail"
                    @click="linkVideo(videoData.video_status)"
                  >
                    <v-progress-circular
                      v-if="videoData.video_status === 'P'"
                      indeterminate
                      color="secondary"
                      :size="20"
                      :width="2"
                      style="
                        position: absolute;
                        top: 37%;
                        right: 50%;
                        transform: translate(50%, 50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    />

                    <span
                      v-if="videoData.video_status === 'S' || videoData.video_status === 'R'"
                      name="lock"
                      style="
                        position: absolute;
                        top: 32%;
                        right: 50%;
                        transform: translate(50%, 50%);
                        background-color: #00000099;
                        border-radius: 100px;
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <v-icon
                        color="secondary"
                        size="18"
                      >
                        {{ videoData.video_status === 'S' ? icons.mdiClockOutline : icons.mdiRecordRec }}
                      </v-icon>
                    </span>

                    <span
                      v-if="videoData.video_status === 'S' || videoData.video_status === 'R' || videoData.video_status === 'P'"
                      style="
                        position: absolute;
                        top: 48%;
                        right: 50%;
                        transform: translate(50%, 50%);
                        font-weight: 700;
                        color: #FF3232;
                      "
                    >
                      {{ videoData.video_status === 'S' ? t('status.scheduled') : videoData.video_status === 'R' ? t('status.recording') : t('status.processing') }}
                    </span>

                    <v-chip
                      class="absolute-chip"
                      color="#1d1d1dd4"
                      small
                    >
                      {{ videoData.video_long }}
                    </v-chip>
                  </v-img>
                </v-carousel-item>

                <div v-else>
                  <v-carousel-item
                    v-for="
                      (item,
                       index)
                        in
                          videoData.thumbnails"
                    :key="index"
                    :src="item"
                    eager
                  >
                    <v-progress-circular
                      v-if="videoData.video_status === 'P'"
                      indeterminate
                      color="secondary"
                      :size="20"
                      :width="2"
                      style="
                        position: absolute;
                        top: 40%;
                        right: 50%;
                        transform: translate(50%, 50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    />

                    <span
                      v-if="videoData.video_status === 'R'"
                      name="lock"
                      style="
                        position: absolute;
                        top: 32%;
                        right: 50%;
                        transform: translate(50%, 50%);
                        background-color: #00000099;
                        border-radius: 100px;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <v-icon
                        color="secondary"
                        size="25"
                      >
                        {{ icons.mdiRecordRec }}
                      </v-icon>
                    </span>

                    <span
                      v-if="videoData.video_status === 'R' || videoData.video_status === 'P'"
                      style="
                        position: absolute;
                        top: 48%;
                        right: 50%;
                        transform: translate(50%, 50%);
                        font-weight: 700;
                        color: #FF3232;
                      "
                    >
                      {{ videoData.video_status === 'R' ? t('status.recording') : t('status.processing') }}
                    </span>
                  </v-carousel-item>
                </div>
              </v-carousel>

              <v-card-actions
                class="dense"
              >
                <v-row class="justify-center mt-5">
                  <v-btn
                    v-if="videoData.video_status === 'A'"
                    class="font-weight-bold"
                    color="secondary"
                    rounded
                    width="100%"
                    @click="linkVideo(videoData.video_status)"
                  >
                    {{ t('video_app.watch_video') }}
                  </v-btn>
                  <span class="text-sm text-center py-3">
                    {{ t('video_app.reproduced') }}
                  </span>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, onMounted } from '@vue/composition-api'
import { useRouter, downloadAppByOS } from '@core/utils'
import 'video.js/dist/video-js.css'
import { mdiPlus, mdiRefresh, mdiLock, mdiDotsVertical, mdiStar, mdiClockOutline, mdiRecordRec } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { getVideoPlaybackType } from '@api'
import themeConfig from '@themeConfig'
import { useUtils } from '@core/libs/i18n'
import { loadLanguageAsync } from '@/plugins/i18n'

export default {
  setup() {
    const videoData = ref({
      end_playback_time: null,
      facility_logo: null,
      facility_name: null,
      field_name: null,
      highlight_name: null,
      highlight_owner: null,
      highlight_owner_avatar: null,
      share_user: null,
      share_user_avatar: null,
      start_playback_time: null,
      thumbnails: null,
      video_date: null,
      video_id: null,
      video_link: null,
      video_long: null,
      video_thumbnail: null,
      video_time: null,
      video_type: null,
    })
    const refVideo = ref(null)
    const refUsername = ref(null)
    const { t } = useUtils()
    const { route, router } = useRouter()

    onMounted(async () => {
      // loadLanguageAsync(navigator.language.includes('en') ? 'en' : 'es' || 'en')
      loadLanguageAsync('en')
      refVideo.value = route.value.params.playback
      refUsername.value = route.value.params.username

      const response = await getVideoPlaybackType(refVideo.value, 'V', refUsername.value)
      if (response.ok) {
        videoData.value = response.data
      } else {
        router.push({
          path: '/video-not-found',
        })
      }
    })

    const linkVideo = status => {
      if (status && status !== 'A') return false

      // window.open(`${window.location.origin}/app-reditect/${videoData.value.video_id}`, '_blank')
      downloadAppByOS(
        `${window.location.origin}/app-reditect/${videoData.value.video_id}`,
        `${window.location.origin}/app-reditect/${videoData.value.video_id}`,
        `${window.location.origin}/app-reditect/${videoData.value.video_id}`,
        true,
      )

      return true
    }

    const downloadApp = () => {
      // if (isAndroid()) window.open('https://play.google.com/store/apps/details?id=com.fanaty', '_blank')
      // if (isIOS()) window.open('https://apps.apple.com/us/app/fanaty/id1461051554', '_blank')
      // if (getOS() === 'Other') window.open('https://fanaty.com/download', '_blank')
      downloadAppByOS(
        'https://play.google.com/store/apps/details?id=com.fanaty',
        'https://apps.apple.com/us/app/fanaty/id1461051554',
        'https://fanaty.com/download',
      )
    }

    return {
      videoData,
      refUsername,
      refVideo,

      // methods
      linkVideo,
      downloadApp,
      avatarText,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // i18n
      t,

      icons: {
        mdiPlus,
        mdiRefresh,
        mdiLock,
        mdiDotsVertical,
        mdiStar,
        mdiClockOutline,
        mdiRecordRec,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
<style scope>
.img-h {
  width: 100% !important;
  height: 56vh !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .img-h {
    width: 100% !important;
    height: 26vh !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .img-h {
    width: 100% !important;
    height: 50vh !important;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .img-h {
    width: 100% !important;
    height: 64vh !important;
  }
}

#video-app {
  font-family: 'Open Sans' !important;
}

.bg-video-app {
  background-color: black;
}

.header-video-app {
  background-color: #ff3232;
  min-height: 6vh;
}

.logo-video-app {
  background-color: black;
  min-height: 10vh;
}

.btn-video-app {
  min-height: 22vh;
  padding: 24px;
}

.auth-wrapper {
  min-height: calc(var(--vh, 1vh) * 84);
}

.img-h .v-responsive__content {
  width: 100% !important;
}

.img-h .v-responsive__sizer {
  padding: 0 !important;
}

.absolute-chip {
  bottom: 10px;
  right: 10px;
  position: absolute;
}
</style>
